/* istanbul ignore file */
import { CognitoAuth } from 'amazon-cognito-auth-js';

// Based on: https://w.amazon.com/bin/view/AmazonLogisticsTechnology/Teams/FMT/TransportationWorkItemService/SOPs/Create_Single_Page_App_with_API_Gateway_for_Internal_Use

//const apiUrl = 'https://tjis2gs7z8.execute-api.us-east-1.amazonaws.com/test/es/search-test-spectrometer-public/kibana_sample_data_ecommerce';
//const apiUrl = 'https://tjis2gs7z8.execute-api.us-east-1.amazonaws.com/test/es/search-test-spectrometer-public/ping';

// TODO: Auth will fail with a confusing error message if "window.location.origin" doesn't match what's in Cognito

export default class Auth {
  domain: string;
  href: string = window.location.href;
  auth: any;
  session: any;
  stage: any;

  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const region = 'us-west-2';
    this.stage = 'beta';

    if (this.stage.startsWith('prod')) {
      this.domain = 'mlroller-prod.auth.us-west-2.amazoncognito.com'; // The domain name in Cognito "App integration > Domain name", without https://
      this.auth = new CognitoAuth({
        AppWebDomain: this.domain,
        ClientId: 'u1fbhuub1o7amq2otsnhv6d1k', // the client ID from Cognito "General settings > App clients" page
        RedirectUriSignIn: window.location.origin, //exactly same as the callbacks URLS in Cognito "App integration > App client settings" page
        RedirectUriSignOut: window.location.origin, //exactly same as the sign out URLS in Cognito "App integration > App client settings" page
        TokenScopesArray: ['openid', 'email'],
        UserPoolId: 'us-west-2_hsMXj9F6Y', // the user pool from Cognito "General settings" page
      });
    } else {
      this.domain = 'mlroller-beta.auth.us-west-2.amazoncognito.com'; // The domain name in Cognito "App integration > Domain name", without https://
      this.auth = new CognitoAuth({
        AppWebDomain: this.domain,
        ClientId: '6kutud0ddjghkrmmd5p9m9ebgd', // the client ID from Cognito "General settings > App clients" page
        RedirectUriSignIn: window.location.origin, //exactly same as the callbacks URLS in Cognito "App integration > App client settings" page
        RedirectUriSignOut: window.location.origin, //exactly same as the sign out URLS in Cognito "App integration > App client settings" page
        TokenScopesArray: ['openid', 'email'],
        UserPoolId: 'us-west-2_SZCyk11ht', // the user pool from Cognito "General settings" page
      });
    }

    // Make user you turn on OAuth2 Authorization Code Grant flow
    this.auth.useCodeGrantFlow();
    this.session = this.auth.getSignInUserSession();
  }

  // This code removes the "?code=..." from the URL. It is because the grant code is not reusable. Sometimes
  // the SDK will report weird message because of using old grant code.
  removeQueryFromLocation() {
    // Replace the href because the Cognito passes the OAuth2 grant code in the query string
    // And the grant code is not reusable
    if (window.history.length > 0) {
      const newHref = window.location.href.split('?')[0];
      window.history.replaceState(undefined, 'Your App Title', newHref);
    }
  }

  // This is the callback function of the sign in button. It actually call sign out function, which behind the scene removes
  //the local session and redirects to Cognito sign out URL. After completion it will redirect to this page again. So there
  // is no need to call auth.getSession() in this function.
  signInButtonOnClick() {
    this.auth.signOut();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showSignInButton(p: { onClick: () => void }) {
    window.alert('Press OK to log in');
    localStorage.clear();
  }

  getUserId() {
    const identity = (this.session.getIdToken().decodePayload() as any)
      .identities[0];
    return identity['userId'];
  }

  getJwtToken() {
    return this.session.getIdToken().getJwtToken();
  }

  isAuthenticated() {
    return this.session.isValid();
  }

  handleAuthentication(callBack: any) {
    // Register callback functions
    // console.log("window.location.origin:", window.location.origin);
    this.auth.userhandler = {
      onFailure: () => {
        // console.log(`Cognito ${this.domain} onFailure`, err);
        this.removeQueryFromLocation();
        this.showSignInButton({ onClick: this.signInButtonOnClick }); // To show sign in button for failed authentication. This require human intervention to prevent infinity loop for unexpected cases.
      },
      onSuccess: () => {
        // console.log(`Cognito ${this.domain} onSuccess`, result);
        this.removeQueryFromLocation();
        // You have logged in :-)
        // console.log("Logged in!");
        window.location?.reload();
        callBack();
      },
    };

    if (this.session.isValid()) {
      // console.log("User was already logged in!");
      // console.log("Session:", this.session);
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const identity = (this.session.getIdToken().decodePayload() as any)
          .identities[0];
        // console.log(`Hello ${identity.userId} of ${identity.providerName}`);
      } catch (ex) {
        console.error('Could not parse identity', ex);
      }
      callBack();
    } else if (this.href.indexOf('?code') > 0) {
      // This is required because Cognito needs to get the authentication result from the query string
      // The parsing is done asynchronously, and the result will be passed to the userHandler.
      // console.log(`Parsing session for ${this.domain}`);
      this.auth.parseCognitoWebResponse(this.href);
      // console.log("Parsed session", this.session);
    } else {
      // Cognito SDK will handle session refresh / authentication.
      this.auth.getSession();
    }
  }

  getStage() {
    return this.stage;
  }
}
